
import { Component, Vue } from 'vue-property-decorator';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { RouteNames } from '@/enums/routes/RouteNames';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import Dashboard from '@/models/Dashboard';
import DashboardRepository from '@/repositories/DashboardRepository';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';

@Component({
    name: 'Dashboards',
    components: { SettingsTopBar },
})
export default class Dasboards extends Vue {
    private filteredDashboards: any = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get columns() {
        return [
            { title: 'Id', dataIndex: 'id', key: 'id' },
            { title: this.$t('Reports'), dataIndex: 'reports', key: 'reports' },
            // { title: 'Creator', dataIndex: 'creator', key: 'creator' },
            // { title: 'Date created', dataIndex: 'createdAt', key: 'createdAt' },
            // { title: 'Action', dataIndex: 'action', key: 'action', scopedSlots: { customRender: 'action' } },
        ];
    }

    // Fetched dashboards / dummy data
    private get dashboards() {
        const dashboardList = DashboardRepository.getAll();
        return dashboardList.map((dashboard: Dashboard) => {
            return {
                key: dashboard.id,
                id: dashboard.id,
                reports: dashboard.dashboardReports.length,
            };
        });
    }

    public get canEditUserRights() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER_RIGHTS);
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get temporaryCmsUserData() {
        return this.$store.getters['temporaryCmsData/cmsUser'];
    }

    private get isCmsUserSuperAdmin() {
        if (this.temporaryCmsUserData == null) {
            return false;
        }

        return this.temporaryCmsUserData.roles.includes('ROLE_SUPER_ADMIN');
    }

    private navigateToCurrentUsersDashboard() {
        this.$router.push({ name: RouteNames.statisticsDashboard });
    }

    private navigateToDashboard(id: string) {
        this.$router.push({ name: RouteNames.statisticsDashboard, params: { dashboardId: id } });
    }

    private onSearch(query: string) {
        this.filteredDashboards = this.dashboards.filter((item: any) => {
            return item.name.toLowerCase().includes(query.toLowerCase());
        });
    }

    private async fetchAllDashboards() {
        try {
            await Dashboard.getAll();
        } catch (e) {
            Promise.reject(e);
        }
    }

    private async mounted() {
        this.loadingOverlay.start();
        await Promise.allSettled([this.fetchAllDashboards()]);
        this.loadingOverlay.stop();
    }
}
